import splide from '@splidejs/splide'

const initialiseSliders = () => {
  const splideInstance = document.querySelectorAll(
    '.splide'
  ) as NodeListOf<HTMLElement>
  if (splideInstance.length > 0) new splide('.splide').mount()
}

export default initialiseSliders
