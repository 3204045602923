const button = document.getElementById('scroll-to-top') as HTMLButtonElement

const scrollFunction = () => {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    button.style.opacity = '1'
  } else {
    button.style.opacity = '0'
  }
}

const scrollToTop = () => {
  scrollFunction()
  window.addEventListener('scroll', scrollFunction, false)
  button.addEventListener(
    'click',
    () => {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    },
    false
  )
}

export default scrollToTop
