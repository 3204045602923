export default class NavigationBar {
  triggerAttr: string = 'data-nav-trigger'
  targetAttr: string = 'data-nav-target'
  constructor() {
    this.#registerEvents()
    this.#setAria()
  }
  // Private methods
  #registerEvents() {
    for (let i = 0; i < this.triggers.length; i++) {
      const trigger = this.triggers[i]
      trigger.addEventListener('click', (e) => {
        this.#triggerClickEvent(e)
      })
    }
  }
  #setAria() {
    for (let i = 0; i < this.triggers.length; i++) {
      const trigger = this.triggers[i]
      this.#setAriaTrigger(trigger, this.active)
    }

    this.#setAriaTarget(this.target, this.active)
  }
  #setAriaTrigger(ele: HTMLElement, state: boolean) {
    const targetId = this.target.getAttribute('id')
    if (targetId) {
      ele.setAttribute('aria-controls', targetId)
    }
    ele.setAttribute('aria-expanded', state.toString())
    ele.setAttribute('role', 'button')
  }
  #setAriaTarget(ele: HTMLElement, state: boolean) {
    ele.setAttribute('aria-expanded', state.toString())
    ele.setAttribute('aria-hidden', (!state).toString())
  }

  // Events
  #triggerClickEvent(e: Event) {
    e.preventDefault()
    this.active = !this.active
    this.#setAria()
  }

  // Getters
  get triggers(): NodeListOf<HTMLElement> {
    return document.querySelectorAll(`[${this.triggerAttr}]`)
  }
  get target(): HTMLElement {
    return document.querySelector(`[${this.targetAttr}]`) as HTMLElement
  }
  get active(): boolean {
    return this.target.classList.contains('active')
  }
  // Setters
  set active(value: boolean) {
    if (value) this.target.classList.add('active')
    else this.target.classList.remove('active')

    for (let i = 0; i < this.triggers.length; i++) {
      const trigger = this.triggers[i]
      if (value) trigger.classList.add('active')
      else trigger.classList.remove('active')
    }
    document.body.classList.toggle('overflow-hidden', value)
  }
}
