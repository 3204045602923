/*
    Hook into form submission and prevent default behavior:
        - Only apply filters if a value is present in the input field.
        - On select change, apply filters.
        - On form submission, apply filters.
        - Swap agency selected card to match the agency filter
    
    Hook into rest form button:
        - Clear all filters and reset the form.
        - Hide the agency selected card.
*/

export default class JobSearchForm {
    constructor() {
        if (this.form === null) return;
        if (this.jobResults === null) return;

        this.registerEvents();
    }
    // ------------------------------
    // Private methods
    private registerEvents() {
        this.form.addEventListener("submit", (e) => {
            e.preventDefault();
            this.submitForm(false);
        });
        this.resetButton?.addEventListener("click", (e) => {
            e.preventDefault();
            this.resetForm();
        });
        this.form.addEventListener("change", (e) => {
            if (e.target instanceof HTMLSelectElement) this.submitForm(false);
        });

        for (const link of this.agencyFilterLinks) {
            link.addEventListener("click", (e) => {
                e.preventDefault();

                const agencyId = link.getAttribute("data-agency-filter");
                if (agencyId === null) return;
                const agencySelect = this.form.querySelector(
                    'select[name="filter[agency]"]',
                ) as HTMLSelectElement | null;
                if (agencySelect === null) return;
                agencySelect.value = agencyId;

                this.submitForm(false);
            });
        }
    }
    private submitUrl(noFilters: boolean) {
        const url = new URL(
            `${window.location.origin}/wp-json/greenhouse/v1/jobs`,
        );

        if (noFilters) return url;

        const formData = new FormData(this.form);
        for (const [key, value] of formData.entries()) {
            if (value !== "") {
                url.searchParams.append(key, value.toString());
            }
        }
        return url;
    }
    private syncQueryParams(url: URL) {
        const newLocation = new URL(window.location.href);
        newLocation.search = url.search;
        window.history.replaceState({}, "", newLocation.href);
    }
    private updateLoadingState(state: boolean) {
        const loading = document.getElementById("job-search-loading");
        if (loading === null) return;
        if (state) {
            loading.classList.remove("hidden");
            return;
        }
        loading.classList.add("hidden");
    }

    // ------------------------------
    // Public methods
    async submitForm(noFilters: boolean ) {
        try {
            
            const url = this.submitUrl(noFilters);

            if (window.location.pathname === "/") {
                window.location.href = "/search-and-apply" + url.search;
                return;
            }

            this.updateLoadingState(true);

            const response = await fetch(url.href, {
                method: "GET",
                headers: {
                    "Content-Type": "text/html",
                },
            });
            const body = await response.text();
    
            const parser = new DOMParser();
            const doc = parser.parseFromString(body, "text/html");
            const jobResults = doc.getElementById("job-search-results");
            if (jobResults === null) {
                throw new Error("Job results not found");
            }
    
            this.jobResults.innerHTML = jobResults.innerHTML
    
            this.syncQueryParams(url)
            this.updateLoadingState(false);
    
            // reset button is re-added via template 
            this.resetButton?.addEventListener("click", (e) => {
                e.preventDefault();
                this.resetForm();
            });

            if (this.jobCount === null) return
            const toal = this.jobResults.querySelectorAll('[data-job-result]').length
            this.jobCount.innerText = toal.toString()
        }
        catch (error) {
            this.updateLoadingState(false);
            console.error(error)
        }
    }
    async resetForm() {
        this.submitForm(true);
        const inputs = this.form.querySelectorAll("input, select") as NodeListOf<
            HTMLInputElement | HTMLSelectElement
        >;
        inputs.forEach((input) => {
            input.value = "";
        });
    }

    // ------------------------------
    // getters
    get form() {
        return document.getElementById("job-search") as HTMLFormElement;
    }
    get resetButton() {
        return document.getElementById("job-search-reset") as HTMLButtonElement | null;
    }
    get jobResults() {
        return document.getElementById(
            "job-search-results",
        ) as HTMLOutputElement;
    }
    get jobCount() {
        return document.getElementById("job-search-count") as HTMLSpanElement | null;
    }
    get agencyFilterLinks() {
        return document.querySelectorAll("[data-agency-filter]") as NodeListOf<
            HTMLAnchorElement
        >;
    }
}
