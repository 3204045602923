import Alpine from "alpinejs";
import NavigationDropdown from "./classes/navigation-dropdown.js";
import NavigationBar from "./classes/navigation-bar.js";
import initialiseSliders from "./functions/initialise-sliders.js";
import agencyDropdown from "./functions/agency-dropdown.js";
import scrollToTop from "./functions/scroll-to-top.js";
import JobSearchForm from "./classes/job-search-form.js";

// @ts-expect-error
window.scrollToContent = (ref: HTMLElement, open: string) => {
    const dropdown = ref.querySelector(
        `[data-content-dropdown="${open}"]`,
    ) as HTMLElement | null;
    if (dropdown) {
        setTimeout(() => {
            dropdown.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }, 100);
    }
};

Alpine.start();

new NavigationDropdown();
new NavigationBar();
new JobSearchForm();

initialiseSliders();
agencyDropdown();
scrollToTop();
