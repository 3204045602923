const agencyDropdown = () => {
  const agency = new URLSearchParams(window.location.search).get('agency')
  if (agency) {
    const agencyDropdown = document.querySelector(
      `[data-agency-dropdown="${agency}"]`
    ) as HTMLElement
    if (agencyDropdown) {
      agencyDropdown.click()
      agencyDropdown.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }
}

export default agencyDropdown
